'use strict';

import { db } from '@/db/idb.js';
import { customerRepository } from '@/customer/customer/repository/customerRespository';
import { addressRepository } from '@/customer/address/repository/addressRepository';
import { itemRepository } from '@/customer/item/repository/itemRepository';
import { itemLocationRepository } from '../../customer/item-location/repository/item-location-repository';
import { itemOrderRepository } from '../../customer/item-order/repository/item-order-repository';

export const cacheService = {
  update,
  getCounts,
  clear,
};

async function update() {
  const customers = await customerRepository.getAllFromApi();

  // If no data
  if (!customers.length) {
    //console.log(customers);
    return false;
  }

  // First clear all current records
  await clear();

  for (const customer of customers) {
    // Get addresses from the customers list
    let addresses = customer.customerAddresses;

    // Add the customer number to the address code to save in local db
    for (const address of addresses) {
      address.customerNumber = customer.customerNumber;
    }
    await addressRepository.add(addresses);

    // Remove the addresses property
    delete customer.customerAddresses;

    // Get items from the customers list
    let items = customer.customerItems;

    for (const item of items) {
      item.customerNumber = customer.customerNumber;
      delete item.itemNumberDescription;
    }
    await itemRepository.add(items);

    // Remove the items property
    delete customer.customerItems;

    // Get items from the customers list
    let itemLocations = customer.customerItemLocations;

    if (!itemLocations) continue;

    for (const itemLocation of itemLocations) {
      itemLocation.customerNumber = customer.customerNumber;
    }
    await itemLocationRepository.add(itemLocations);

    // Remove the address items property
    delete customer.customerItemLocations;


    // Get items from the customers item on order list
    let itemOrders = customer.customerItemOnOrder;

    if (!itemOrders) continue;

    for (const itemOrder of itemOrders) {
      itemOrder.customerNumber = customer.customerNumber;
    }
    await itemOrderRepository.add(itemOrders);

    // Remove the itemOnOrder property
    delete customer.customerItemOnOrder;
  }

  await customerRepository.add(customers);

  let counts = getCounts();

  return counts;
}

async function getCounts() {
  let counts = {};
  counts.customers = await customerRepository.getLocalCount();
  counts.addresses = await addressRepository.getLocalCount();
  counts.items = await itemRepository.getLocalCount();
  counts.itemLocations = await itemLocationRepository.getLocalCount();

  return counts;
}

async function clear() {
  (await db).clear('customer');
  (await db).clear('customerAddress');
  (await db).clear('customerItem');
  (await db).clear('customerAddressItem');
  (await db).clear('customerItemOrder');
  //(await db).clear('customerOrder');

  return true;
}
