<template>
  <div
    class="offcanvas"
    :class="props.type"
    ref="offcanvasRef"
    tabindex="-1"
    aria-labelledby="offcanvas"
    data-bs-scroll="false"
    data-bs-backdrop="false"
    v-if="offcanvasOpen"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">
        <slot name="header"></slot>
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body text-end d-flex" data-bs-dismiss="offcanvas">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import { Offcanvas } from 'bootstrap';

// Props
const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});

// Reactive properties
const offcanvasOpen = ref(false);

// Template properties
const offcanvasRef = ref(null);

// Local properties
let offcanvas = null;

const open = async () => {
  offcanvasOpen.value = true;
  // Wait for re-render
  await nextTick();

  offcanvas = new Offcanvas(offcanvasRef.value);
  offcanvas.show();

  offcanvasRef.value.addEventListener('hidden.bs.offcanvas', () => {
    offcanvasOpen.value = false;
  });
};

const close = () => {
  offcanvas.hide();
  offcanvasOpen.value = false;
};

// Expose functions
defineExpose({
  open,
  close,
});
</script>
