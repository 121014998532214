'use strict';

import CustomerView from '@/customer/customer/view/CustomerView.vue';

export const customerRoutes = [
  {
    path: '/customers',
    component: CustomerView,
    children: [
      {
        path: '',
        name: 'CustomerList',
        component: () =>
          import('@/customer/customer/view/CustomerListView.vue'),
      },
      {
        path: '/customers/:customerNumber',
        name: 'CustomerDetail',
        props: true,
        redirect: { name: 'CustomerAddressList' }, // Redirect to address list
      },
      {
        path: '/customers/:customerNumber/addresses',
        name: 'CustomerAddressList',
        props: true,
        component: () => import('@/customer/address/view/AddressListView.vue'),
      },
      {
        path: '/customers/:customerNumber/items',
        name: 'CustomerItemList',
        props: true,
        component: () => import('@/customer/item/view/ItemListView.vue'),
      },
      {
        path: '/customers/:customerNumber/item-locations',
        name: 'CustomerItemLocationList',
        props: true,
        component: () => import('@/customer/item-location/view/ItemLocationListView.vue'),
      },
    ],
  },
];
