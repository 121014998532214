'use strict';

import { db } from '@/db/idb.js';

export const itemLocationRepository = {
  getAllByCustomer,
  getAllByCustomerItem,
  getAllByCustomerAndAddress,
  getLocalCount,
  add,
};

async function getAllByCustomer(customer) {
  // Get all items by customer from local db
  return await (
    await db
  ).getAllFromIndex('customerAddressItem', 'customerNumber', customer);
}

async function getAllByCustomerItem(customer, customerItemNumber) {
  // Get all items by customer from local db
  return await (
    await db
  ).getAllFromIndex('customerAddressItem', 'customerItemNumber', [
    customer,
    customerItemNumber,
  ]);
}

async function getAllByCustomerAndAddress(customerNumber, addressCode) {
  // Get all customers from local db
  return await (
    await db
  ).getAllFromIndex('customerAddressItem', 'customerNumberAddressCode', [
    customerNumber,
    addressCode,
  ]);
}

async function getLocalCount() {
  return await (await db).count('customerAddressItem');
}

async function add(items) {
  const tx = (await db).transaction('customerAddressItem', 'readwrite');

  // Create a list of promises that can be all run together
  const promises = [];
  for (const item of items) {
    // Put each item into the store
    promises.push(tx.store.put(item));
  }
  promises.push(tx.done);

  await Promise.all(promises);

  return items;
}
