import { openDB } from 'idb';

// Open store by: (await db).<function>()
export const db = openDB('thruway', 4, {
  upgrade: (db, oldVersion, newVersion, transaction) => {
    switch (oldVersion) {
      case 0: {
        console.log('Creating database version 1');
        // Create customer store
        db.createObjectStore('customer', {
          keyPath: 'customerNumber',
        });

        // Create cusomter address store
        const customerAddress = db.createObjectStore('customerAddress', {
          keyPath: ['customerNumber', 'addressCode'],
        });
        customerAddress.createIndex('customerNumber', 'customerNumber');
        customerAddress.createIndex('customerAddressCode', [
          'customerNumber',
          'addressCode',
        ]);

        // Create item store
        const item = db.createObjectStore('customerItem', {
          keyPath: ['customerNumber', 'customerItemNumber', 'itemNumber'],
        });
        item.createIndex('customerNumber', 'customerNumber');
        item.createIndex('customerItemNumber', [
          'customerNumber',
          'customerItemNumber',
        ]);
        item.createIndex('itemNumber', 'itemNumber');

        // Create order store
        const order = db.createObjectStore('customerOrder', {
          keyPath: 'id',
          autoIncrement: true,
        });
        order.createIndex('userOrder', 'user');
        order.createIndex('userCustomerNumber', ['user', 'customerNumber']);

        // Create cusomter keyval store
        db.createObjectStore('keyval');
      }
      case 1: {
        console.log('Creating database version 2');
        // Create cusomter address store
        const customerAddressItem = db.createObjectStore('customerAddressItem', {
          keyPath: ['customerNumber', 'addressCode', 'customerItemNumber', 'itemNumber'],
        });
        customerAddressItem.createIndex('customerNumberAddressCode', [
          'customerNumber',
          'addressCode'
        ]);
      }
      case 2: {
        console.log('Creating database version 3');
        // Create cusomter index for customer address items
        const customerAddressItem = transaction.objectStore('customerAddressItem');
        customerAddressItem.createIndex('customerNumber', 'customerNumber');
      }
      case 3: {
        console.log('Creating database version 4');
        // Create cusomter address store
        const customerItemOrder = db.createObjectStore('customerItemOrder', {
          keyPath: ['customerNumber', 'customerPoNumber', 'customerItemNumber', 'itemNumber'],
        });
        customerItemOrder.createIndex('customerNumber', 'customerNumber');

        break;
      }
      default: {
        console.error('Unknown db version');
      }
    }
  },
});
